import React, { useEffect } from "react";
import "./style.scss";
import { Link } from "gatsby";
import logo from "../../images/logo.png";

export default function Navbar() {
  useEffect(() => {
    const nav = document.querySelector("nav");
    const layout = document.getElementById("layout");
    let isSticky = false;
    document.addEventListener("scroll", () => {
      let elDistanceToTop = nav.getBoundingClientRect().top;

      //   console.log(elDistanceToTop);
      if (window.scrollY === 0) {
        if (isSticky) {
          nav.classList.remove("navbar--sticky");
          layout.classList.remove("layout--stickypadding");
          isSticky = false;
        }
      }
      if (elDistanceToTop < 0) {
        if (!isSticky) {
          nav.classList.add("navbar--sticky");
          layout.classList.add("layout--stickypadding");

          isSticky = true;
        }
      }
    });
  }, []);

  return (
    <nav className="navbar">
      <Link style={{ textDecoration: "none" }} to="/">
        <div className="navbar__logo">
          <img
            className="navbar__logo__img"
            alt="Chinmaya Logo"
            src={logo}
          ></img>
          <div className="navbar__logo__text">
            Chinmaya Vidyalaya
            <br />
            Vasant Vihar, New Delhi
          </div>
        </div>
      </Link>

      <div className="navbar__links">
        <div className="menu1">
          <div className="navbar__links__link">
            <Link to="/contact-information/">
              Connect <i className="fas fa-chevron-down"></i>
            </Link>
            <div className="navbar__links__link__dropdown navbar__links__link__dropdown--4">
              <div className="navbar__links__link__dropdown__blur"></div>
              <div className="navbar__links__link__dropdown__content">
                <div className="row">
                  <div className="col col-12">
                    <Link
                      to="/contact-information/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Contact Us
                    </Link>{" "}
                    <Link
                      to="/alumni/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Alumni
                    </Link>
                    <Link
                      to="/careers/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Careers
                    </Link>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>

          {/* <div className="navbar__links__link">
            <Link to="/contact-information/">Contact</Link>
          </div> */}

          <div className="navbar__links__link">
            <a
              className=""
              href="https://cvvv.iwsportal.com:572/Account/Login.aspx"
              target="_blank"
            >
              Login
            </a>
          </div>
          <div className="navbar__links__link">
            <a className="" href="/blog/">
              Blog
            </a>
          </div>
          <a
            className="e-newsletter-btn"
            href="http://abhyudya.chinmayavvdelhi.ac.in/?page=0"
            style={{ backgroundColor: "#D75431", color: "white" }}
          >
            E-MAGAZINE
          </a>
          <a
            className="e-newsletter-btn"
            href="/e-newsletters/"
            target="_blank"
          >
            E-NEWSLETTERS
          </a>
        </div>
        <div className="menu2">
          <div className="navbar__links__link">
            <Link to="/">Home</Link>
          </div>

          <div className="navbar__links__link">
            About Us <i className="fas fa-chevron-down"></i>
            <div className="navbar__links__link__dropdown navbar__links__link__dropdown--1">
              <div className="navbar__links__link__dropdown__blur"></div>
              <div className="navbar__links__link__dropdown__content">
                <div className="row">
                  <div className="col col-12">
                    <Link
                      to="/chinmaya-mission-trust/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Chinmaya Mission
                    </Link>
                    <Link
                      to="/chinmaya-vision-programme"
                      className="navbar__links__link__dropdown__link"
                    >
                      Chinmaya Vision Program
                    </Link>
                    <Link
                      to="/gurudevs-journey/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Gurudev's Journey
                    </Link>

                    <Link
                      to="/principal-message/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Principal's Message
                    </Link>
                    <Link
                      to="/managing-committee/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Managing Committee
                    </Link>
                    <Link
                      to="/pta-executive-committee/"
                      className="navbar__links__link__dropdown__link"
                    >
                      PTA Executive Committee
                    </Link>
                    <Link
                      to="/hall-of-fame/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Awards
                    </Link>
                    <Link
                      to="/press/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Press
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="navbar__links__link">
            Information <i className="fas fa-chevron-down"></i>
            <div className="navbar__links__link__dropdown navbar__links__link__dropdown--2">
              <div className="navbar__links__link__dropdown__blur"></div>
              <div className="navbar__links__link__dropdown__content">
                <div className="row">
                  <div className="col col-3">
                    <div className="navbar__links__link__dropdown__heading">
                      School Information
                    </div>
                    <a
                      href="http://student.iwsportal.com/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Online Fee Payment
                    </a>

                    <a
                      href="/fee-structure/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Fee Structure
                    </a>

                    <Link
                      to="/cbse-corner/"
                      className="navbar__links__link__dropdown__link"
                    >
                      CBSE Corner
                    </Link>
                    <Link
                      to="/transfer-certificate/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Transfer Certificate
                    </Link>
                    <Link
                      to="/rules-and-regulations/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Rules & Regulations
                    </Link>

                    <Link
                      to="/transportation/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Transportation
                    </Link>
                    <Link
                      to="/school-events/"
                      className="navbar__links__link__dropdown__link"
                    >
                      School Events
                    </Link>

                    <Link
                      to="/circulars/"
                      className="navbar__links__link__dropdown__link"
                    >
                      School Circulars
                    </Link>
                    <Link
                      to="/school-uniform/"
                      className="navbar__links__link__dropdown__link"
                    >
                      School Uniform
                    </Link>
                    <Link
                      to="/infrastructure/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Infrastructure
                    </Link>

                    <Link
                      to="/school-gallery/"
                      className="navbar__links__link__dropdown__link"
                    >
                      School Gallery
                    </Link>

                    <a
                      href="http://abhyudya.chinmayavvdelhi.ac.in/"
                      target="_blank"
                      className="navbar__links__link__dropdown__link"
                    >
                      Annual E-Magazine
                    </a>
                    <Link
                      to="/podcasts/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Podcasts
                    </Link>
                  </div>
                  <div className="col col-2">
                    {" "}
                    <Link
                      to="/academics/"
                      className="navbar__links__link__dropdown__heading"
                    >
                      Academics
                    </Link>
                    <Link
                      to="/subjects/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Subjects
                    </Link>{" "}
                    <Link
                      to="/syllabus/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Syllabus
                    </Link>
                    <Link
                      to="/exam-schedule/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Examination Schedule
                    </Link>
                  </div>
                  <div className="col col-2">
                    <div className="navbar__links__link__dropdown__heading">
                      Co-Curriculars
                    </div>

                    <Link
                      to="/performing-arts/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Performing Arts
                    </Link>
                    <Link
                      to="/club/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Clubs
                    </Link>
                    <Link
                      to="/visual-arts/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Visual Arts
                    </Link>
                    <Link
                      to="/sports/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Sports
                    </Link>
                    <Link
                      to="/vyaktitva/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Vyaktitva
                    </Link>

                    {/* <Link className="navbar__links__link__dropdown__link">
                      Signature Activities
                    </Link> */}
                  </div>
                  <div className="col col-2">
                    <div className="navbar__links__link__dropdown__heading">
                      Achievements
                    </div>
                    <Link
                      to="/cbse-results/"
                      className="navbar__links__link__dropdown__link"
                    >
                      CBSE Results
                    </Link>
                    <Link
                      to="/achievements/external-events"
                      className="navbar__links__link__dropdown__link"
                    >
                      External Events
                    </Link>

                    <Link
                      to="/school-council"
                      className="navbar__links__link__dropdown__link"
                    >
                      School Council
                    </Link>
                  </div>

                  <div className="col col-2">
                    <Link
                      to="/infrastructure"
                      className="navbar__links__link__dropdown__heading"
                    >
                      Infrastructure
                    </Link>
                    <Link
                      to="/atl/"
                      className="navbar__links__link__dropdown__link"
                    >
                      ATL Lab
                    </Link>
                    <Link
                      to="/laboratories/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Laboratories
                    </Link>
                    <Link
                      to="/library/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Library
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="navbar__links__link">
            Admissions <i className="fas fa-chevron-down"></i>
            <div className="navbar__links__link__dropdown navbar__links__link__dropdown--3">
              <div className="navbar__links__link__dropdown__blur"></div>
              <div className="navbar__links__link__dropdown__content">
                <div className="row">
                  <div className="col col-12">
                    <Link
                      to="/nursery-admissions/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Nursery
                    </Link>
                    <Link
                      to="/admissions/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Other Classes
                    </Link>
                    <Link
                      to="/online-registration-process/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Application Process
                    </Link>
                    <Link
                      to="/registrations/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Registrations
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="socialmedia">
            <a
              target="_blank"
              href="https://www.facebook.com/Chinmaya-Vidyalaya-Vasant-Vihar-Delhi-136530329794825/"
              className="social__icon"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
            <a
              target="_blank"
              href="https://instagram.com/chinmaya_vidyalaya_nd?igshid=NTdlMDg3MTY="
              className="social__icon"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UCQqKBe4Q5ZgC7Y-KoCPzGyQ"
              className="social__icon"
            >
              <i className="fab fa-youtube"></i>
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/chinmaya-vidyalaya-vasant-vihar/?viewAsMember=true"
              className="social__icon"
            >
              <i className="fab fa-linkedin-in"></i>
            </a>
            {/* <a
              target="_blank"
              href="https://twitter.com/CV_NewDelhi"
              className="social__icon"
            >
              <i className="fa-brands fa-x-twitter"></i>
            </a> */}
          </div>
        </div>
      </div>
    </nav>
  );
}
