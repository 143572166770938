import React, { useState } from "react";
import "./style.scss";
import logo from "../../images/logo.png";

export default function NavbarMobile() {
  const [dropdownActive, setDropdownActive] = useState(false);
  function toggleDropdown(id) {
    const dropdown = document.getElementById(id);
    dropdown.classList.toggle("navbarmobile__links__link__dropdown--active");
  }

  return (
    <nav className="navbarmobile noselect">
      <a style={{ textDecoration: "none" }} href="/">
        <div className="navbarmobile__logo">
          <img
            className="navbarmobile__logo__img"
            alt="Chinmaya Logo"
            src={logo}
          ></img>
        </div>
      </a>

      <div>
        <div className="navbar__logo__text">
          Chinmaya Vidyalaya
          <br />
          Vasant Vihar, Delhi
        </div>
      </div>

      <div
        onClick={() => setDropdownActive(!dropdownActive)}
        className="navbarmobile__hamburger"
      >
        <i className="fas fa-bars"></i>
      </div>

      <div
        className={`navbarmobile__links ${
          dropdownActive && "navbarmobile__links--active"
        }`}
      >
        <div className="navbarmobile__links__link">
          <a
            style={{
              textDecoration: "none",
              color: "#182951",
              display: "block",
            }}
            href="/"
          >
            Home
          </a>
        </div>
        <div className="navbarmobile__links__link">
          <span
            onClick={() => {
              toggleDropdown("dropdown1");
            }}
          >
            {" "}
            About <i className="fas fa-chevron-down"></i>
          </span>
          <div
            id="dropdown1"
            className="navbarmobile__links__link__dropdown navbarmobile__links__link__dropdown--1"
          >
            <div className="navbarmobile__links__link__dropdown__blur"></div>
            <div className="navbarmobile__links__link__dropdown__content">
              <div className="row">
                <div className="col col-12">
                  <a
                    href="/chinmaya-mission-trust/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Chinmaya Mission
                  </a>
                  <a
                    href="/chinmaya-vision-programme"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Chinmaya Vision Program
                  </a>
                  <a
                    href="/gurudevs-journey/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Gurudev's Journey
                  </a>

                  <a
                    href="/principal-message/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Principal's Message
                  </a>
                  <a
                    href="/managing-committee/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Managing Committee
                  </a>
                  <a
                    href="/pta-executive-committee/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    PTA Executive Committee
                  </a>
                  <a
                    href="/hall-of-fame/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    School Awards
                  </a>
                  <a
                    href="/press/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Press
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="navbarmobile__links__link">
          <span
            onClick={() => {
              toggleDropdown("dropdown2");
            }}
          >
            Information <i className="fas fa-chevron-down"></i>
          </span>
          <div
            id="dropdown2"
            className="navbarmobile__links__link__dropdown navbarmobile__links__link__dropdown--2"
          >
            <div className="navbarmobile__links__link__dropdown__blur"></div>
            <div className="navbarmobile__links__link__dropdown__content">
              <div className="row">
                <div className="col col-12">
                  <div className="navbarmobile__links__link__dropdown__heading">
                    School Information
                  </div>

                  <a
                    href="http://student.iwsportal.com/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Online Fee Payment
                  </a>

                  <a
                    href="/fee-structure/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Fee Structure
                  </a>
                  <a
                    href="/cbse-corner/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    CBSE Corner
                  </a>
                  <a
                    href="/transfer-certificate/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Transfer Certificate
                  </a>
                  <a
                    href="/rules-regulations/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Rules & Regulations
                  </a>
                  <a className="navbarmobile__links__link__dropdown__link">
                    Transportation
                  </a>
                  <a
                    href="/school-events/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    School Events
                  </a>
                  <a
                    href="/circulars/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    School Circulars
                  </a>
                  <a
                    href="/school-uniform/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    School Uniform
                  </a>
                  <a
                    href="/infrastructure"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Infrastructure
                  </a>
                  <a
                    href="/school-gallery/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    School Gallery
                  </a>
                  <a
                    href="http://abhyudya.chinmayavvdelhi.ac.in/"
                    target="_blank"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Annual E-Magazine
                  </a>
                  <a
                    href="/podcasts/"
                    target="_blank"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Podcasts
                  </a>
                </div>
                <div className="col col-12">
                  <a
                    href="/academics/"
                    className="navbarmobile__links__link__dropdown__heading"
                  >
                    Academics
                  </a>
                  <a
                    href="/subjects/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Subjects
                  </a>{" "}
                  <a
                    href="/syllabus/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Syllabus
                  </a>{" "}
                  <a
                    href="/exam-schedule/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Examination Schedule
                  </a>
                </div>

                <div className="col col-12">
                  <div className="navbarmobile__links__link__dropdown__heading">
                    Co-Curriculars
                  </div>

                  <a
                    href="/performing-arts/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Performing Arts
                  </a>
                  <a
                    href="/club/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Clubs
                  </a>
                  <a
                    href="/visual-arts/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Visual Arts
                  </a>
                  <a
                    href="/sports/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Sports
                  </a>
                  <a
                    href="/vyaktitva/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Vyaktitva
                  </a>
                </div>
                <div className="col col-12">
                  <div className="navbarmobile__links__link__dropdown__heading">
                    Acheivements
                  </div>
                  <a
                    href="/cbse-results"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    CBSE Results
                  </a>

                  <a
                    href="/achievements/external-events"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    External Events
                  </a>
                  <a
                    href="/school-council"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    School Council
                  </a>
                </div>

                <div className="col col-12">
                  <a
                    href="/infrastructure"
                    className="navbarmobile__links__link__dropdown__heading"
                  >
                    Infrastructure
                  </a>
                  <a
                    href="/atl/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    ATL Lab
                  </a>
                  <a
                    href="/laboratories/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Laboratories
                  </a>
                  <a
                    href="/library/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Library
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="navbarmobile__links__link">
          <span
            onClick={() => {
              toggleDropdown("dropdown3");
            }}
          >
            Admissions <i className="fas fa-chevron-down"></i>
          </span>
          <div
            id="dropdown3"
            className="navbarmobile__links__link__dropdown navbarmobile__links__link__dropdown--3"
          >
            <div className="navbarmobile__links__link__dropdown__blur"></div>
            <div className="navbarmobile__links__link__dropdown__content">
              <div className="row">
                <div className="col col-12">
                  <a
                    href="/nursery-admissions/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Nursery
                  </a>
                  <a
                    href="/admissions/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Other Classes
                  </a>
                  <a
                    href="/online-registration-process/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Application Process
                  </a>
                  <a
                    href="/registrations"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Registrations
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="navbarmobile__links__link">
          <a
            style={{
              textDecoration: "none",
              color: "#182951",
              display: "block",
            }}
            href="/contact-information/"
          >
            Contact
          </a>
        </div>
        <div className="navbarmobile__links__link">
          <a
            style={{
              textDecoration: "none",
              color: "#182951",
              display: "block",
            }}
            href="/careers/"
          >
            Careers
          </a>
        </div>
        <div className="navbarmobile__links__link">
          <a
            style={{
              textDecoration: "none",
              color: "#182951",
              display: "block",
            }}
            href="/alumni/"
          >
            Alumni
          </a>
        </div>
        <div>
          <a
            className="navbarmobile__links__link"
            href="https://cvvv.iwsportal.com:572/Account/Login.aspx"
          >
            {" "}
            Login{" "}
          </a>
          <div className="navbarmobile__links__link">
            <a
              style={{
                textDecoration: "none",
                color: "#182951",
                display: "block",
              }}
              href="/blog/"
            >
              Blog
            </a>
          </div>
        </div>
        <a
          className="navbarmobile__links__link e-newsletter-btn"
          href="http://abhyudya.chinmayavvdelhi.ac.in/?page=0"
        >
          E-MAGAZINE
        </a>
        <a
          className="navbarmobile__links__link e-newsletter-btn"
          href="/e-newsletters/"
        >
          E-NEWSLETTERS
        </a>

        <div className="socialmedia">
          <a
            target="_blank"
            href="https://www.facebook.com/Chinmaya-Vidyalaya-Vasant-Vihar-Delhi-136530329794825/"
            className="social__icon"
          >
            <i className="fab fa-facebook-f"></i>
          </a>
          <a
            target="_blank"
            href="https://instagram.com/chinmaya_vidyalaya_nd?igshid=NTdlMDg3MTY="
            className="social__icon"
          >
            <i className="fab fa-instagram"></i>
          </a>
          <a
            target="_blank"
            href="https://www.youtube.com/channel/UCQqKBe4Q5ZgC7Y-KoCPzGyQ"
            className="social__icon"
          >
            <i className="fab fa-youtube"></i>
          </a>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/chinmaya-vidyalaya-vasant-vihar/?viewAsMember=true"
            className="social__icon"
          >
            <i className="fab fa-linkedin-in"></i>
          </a>
          {/* <a
            target="_blank"
            href="https://twitter.com/CV_NewDelhi"
            className="social__icon"
          >
            <i className="fa-brands fa-x-twitter"></i>
          </a> */}
        </div>
      </div>
    </nav>
  );
}
